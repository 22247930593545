import axios from "axios";
import {
  deleteSessionStorageToken,
  getClientId,
  persistSessionStorageToken,
  persistToken,
  readAccountInfo,
  readRefreshToken,
  readSessionStorageToken,
  readToken,
} from "../services/localStorage.service";
import { ApiError } from "./ApiError";
import { refreshToken } from "./auth.api";

export const baseURL = "https://api.track.lease/api/";
export const httpApi = axios.create({
  baseURL: baseURL,
});

httpApi.interceptors.request.use((config) => {
  const token = readToken();
  const sessionStorageUser = readSessionStorageToken().accountInfo;
  const sessionStorageRefreshToken = readSessionStorageToken().refreshToken;
  const localStorageUser = readAccountInfo();
  const localStorageRefreshToken = readRefreshToken();
  if (sessionStorageUser && localStorageUser && sessionStorageRefreshToken && sessionStorageRefreshToken) {
    try {
      const sessionStorageUserObj = JSON.parse(sessionStorageUser);
      const localStorageUserObj = JSON.parse(localStorageUser);

      const sessionUser = sessionStorageUserObj.UserFullName && sessionStorageUserObj.UserID
      const localUser = localStorageUserObj.UserFullName && localStorageUserObj.UserID
      const isSameUser = sessionUser === localUser && sessionStorageRefreshToken === localStorageRefreshToken
      
      if (!isSameUser) {
        window.location.href = "/auth/login";
        deleteSessionStorageToken()
        return Promise.reject(new Error("User session mismatch. Redirecting to login."));
      }
    } catch (error) {
      console.error('Error parsing user info from storage:', error);
      window.location.href = "/auth/login";
      deleteSessionStorageToken()
      return Promise.reject(new Error("Error parsing user info. Redirecting to login."));
    }
  }

  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };
  }
  return config;
});

let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

function onAccessTokenFetched(token: string) {
  refreshSubscribers.forEach((callback) => callback(token));
  refreshSubscribers = [];
}

function addSubscriber(callback: (token: string) => void) {
  refreshSubscribers.push(callback);
}

httpApi.interceptors.response.use(undefined, (error: any) => {
  const originalRequest = error.config;
  const apiStatus = error?.response?.status;
  const isClientNotFound = error?.response.data.message_description === 'Invalid Client ID.'

  const isPersistanceFailed = originalRequest.url === "CmsAuth?callType=persistent" && apiStatus === 401;
  if (isPersistanceFailed) window.location.href = "/logout";
  const isClientChange = originalRequest.url === "CmsAuth?callType=set_client_of_account";

  if (apiStatus === 401 || (isClientChange && apiStatus === 406)) {
    let Isclientchange = isClientChange && apiStatus === 406;
    if (!isRefreshing) {
      isRefreshing = true;
      const preRefreshToken = readRefreshToken();
      refreshToken({ refresh_token: preRefreshToken })
        .then((response: { token: string, refresh_token: string }) => {
          if (window.location.pathname.split('/')[1] === 'client' && !Isclientchange) {
            const client_Id = getClientId();
            setClientTokenWithRefreshedToken(response.token, client_Id)
              .then(({ data }) => {
                persistToken(data.token, data.refresh_token);
                onAccessTokenFetched(data.token);
                persistSessionStorageToken(data.token, data.refresh_token)
                isRefreshing = false;
              })
              .catch(() => {
                isRefreshing = false;
              });
          } else {
            // For paths other than 'client', handle token
            persistToken(response.token, response.refresh_token);
            onAccessTokenFetched(response.token);
            persistSessionStorageToken(response.token, response.refresh_token)
            isRefreshing = false;
          }
        });
    }

    const retryOriginalRequest = new Promise<any>((resolve, reject) => {
      addSubscriber((token) => {
        originalRequest.headers.Authorization = `Bearer ${token}`;
        resolve(httpApi(originalRequest));
      });
    });
    return retryOriginalRequest;
  }
  throw new ApiError<ApiErrorData>(
    error && error.response?.data["message_description"]
      ? error.response?.data["message_description"]
      : error.response?.data["message"]
        ? error.response?.data["message"]
        : error.message
  );
});

export interface ApiErrorData {
  message: string;
}

function setClientTokenWithRefreshedToken(access_token: string, client_Id: string) {
  const clientTokenApi = axios.create({
    baseURL: baseURL,
  });
  clientTokenApi.defaults.headers.common.Authorization = `Bearer ${access_token}`;

  // Make the setClientToken request using the new Axios instance
  return clientTokenApi.post("CmsAuth?callType=set_client_of_account", { client_id: client_Id });
}